import './typing-animation.scss'

export default function TypingAnimation({
  showAnimation = false,
}: {
  showAnimation?: boolean
}) {
  return (
    <div>
      {showAnimation && (
        <div
          data-testid='chatbot-loading-response'
          className='typingBubble'
        >
          <span />
          <span />
          <span />
        </div>
      )}
    </div>
  )
}
