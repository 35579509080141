'use client'

import SvgCopyText from 'app/components/Svg/SvgCopyText'
import SvgThumbsDown from 'app/components/Svg/SvgThumbsDown'
import SvgThumbsUp from 'app/components/Svg/SvgThumbsUp'
import { useCallback, useEffect, useRef, useState } from 'react'
import Cookies from 'universal-cookie'
import Markdown from 'app/components/Markdown.client'
import { TrackImpression } from 'app/components/Snowplow/TrackImpression/TrackImpression.client'
import { MarkdownTracker } from 'app/components/Markdown.client/types'
import FeedbackModal from './FeedbackModal.client'
import trackNavigationClick from '../Snowplow/lib/trackNavigationClick.client'
import trackEngagementClick from '../Snowplow/lib/trackEngagementClick.client'
import './response-chat-bubble.scss'

interface IResponseChatBubbleProps {
  children: React.ReactNode
  className?: string
  hideActionBlock?: boolean
  responseId: string
}

enum Feedback {
  MEH,
  GOOD,
  BAD,
}

export default function ResponseChatBubble({
  children,
  className,
  hideActionBlock,
  responseId
}: IResponseChatBubbleProps) {
  const [feedback, setFeedback] = useState<Feedback>(Feedback.MEH)
  const [copied, setCopied] = useState(false)
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [surveyId, setSurveyId] = useState('')
  const [feedbackId, setFeedbackId] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  const thumbsDownButtonRef = useRef<HTMLButtonElement>(null)
  const cookies = new Cookies()
  const accessToken: string = cookies.get('user-jwt')

  const trackCopy = useCallback(() => {
    trackEngagementClick({
      unit_name: 'conversation',
      unit_location: 'inline',
      unit_variation: 'copy_text',
      component_id: responseId,
    })
  }, [responseId])

  useEffect(() => {
    const divElement = ref.current
    if (!divElement) return

    divElement.addEventListener('copy', trackCopy)

    return () => {
      divElement.removeEventListener('copy', trackCopy)
    }
  }, [ref, trackCopy])

  const copyText = () => {
    const element = ref.current
    if (!element) return

    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(element)
    selection?.removeAllRanges()
    selection?.addRange(range)
    // execCommand is deprecated but I couldn't find another solution that copies links as well
    document.execCommand('copy')
    selection?.removeAllRanges()

    setCopied(true)
    setTimeout(() => setCopied(false), 5000)
  }

  const startSurvey = async (surveyKey: string) => {
    const response = await fetch('/api/chat/bho/start', {
      method: 'POST',
      body: JSON.stringify({
        jwt: accessToken,
        surveyKey,
      }),
    })
    return response.json()
  }

  const updateSurvey = async (sId: string, surveyKey: string) => {
    const response = await fetch('/api/chat/bho/update', {
      method: 'PUT',
      body: JSON.stringify({
        jwt: accessToken,
        surveyKey,
        surveyId: sId,
        data: {
          responseId,
        },
      }),
    })
    return response.json()
  }

  const sendToLangsmith = async (
    traceId: string,
    feedbackScore: 0 | 1
  ): Promise<string> => {
    const response = await fetch('/api/chat/feedback', {
      method: 'POST',
      body: JSON.stringify({
        traceId,
        feedback: feedbackScore,
      }),
    })

    const res = await response.json()
    return res.feedbackId
  }

  const onThumbsUpOnClick = async () => {
    if (feedback !== Feedback.MEH) return

    setFeedback(Feedback.GOOD)

    await sendToLangsmith(responseId, 1)

    const surveyKey = 'chatbot_thumbs_up'
    const start = await startSurvey(surveyKey)
    if (start.surveyId) {
      await updateSurvey(start.surveyId, surveyKey)
    }

    trackEngagementClick({
      unit_name: 'feedback',
      unit_variation: 'positive_feedback',
      unit_location: 'inline',
      component_id: responseId,
    })
  }

  const onThumbsdownClick = async () => {
    if (feedback !== Feedback.MEH) return
    
    setFeedback(Feedback.BAD)
    setShowFeedbackModal(true)

    const response = await sendToLangsmith(responseId, 0)
    setFeedbackId(response)

    const surveyKey = 'chatbot_thumbs_down'
    const start = await startSurvey(surveyKey)
    if (start.surveyId) {
      setSurveyId(start.surveyId)
      await updateSurvey(start.surveyId, surveyKey)
    }

    trackNavigationClick({
      unit_name: 'feedback',
      unit_variation: 'negative_feedback',
      unit_location: 'inline',
      component_id: responseId,
      click_type: 'button',
    })
  }

  const tracker: MarkdownTracker = ({ children: childText, href }) => {
    trackNavigationClick({
      click_type: 'text',
      unit_name: 'conversation',
      unit_location: 'inline',
      component_id: responseId,
      link_text: childText,
      linked_content_url: href,
    })
  }

  const renderChildren = () => {
    if (typeof children === 'string') {
      return <Markdown tracker={tracker}>{children}</Markdown>
    }
    return children
  }

  if (!children) return null

  return (
    <div className={`responseChatBubble ${className ?? ''}`}>
      <div
        ref={ref}
        id={responseId}
        data-testid='chatbot-response-bubble'
        className='responseChatBubble__message'
      >
        <TrackImpression unit_name='conversation' unit_location='inline' />
        {responseId ? (
          <TrackImpression
            unit_name='conversation'
            unit_location='inline'
            button_name='chatbot_response'
            component_id={responseId}
          >
            {renderChildren()}
          </TrackImpression>
        ) : (
          renderChildren()
        )}
      </div>
      {!hideActionBlock && (
        <div
          className='responseChatBubble__actionBlock'
          data-testid='chatbot-action-block'
        >
          <button
            className={`responseChatBubble__actionBlock__button
              ${copied && 'responseChatBubble__actionBlock__button--copied'}`}
            onClick={copyText}
            type='button'
            aria-label='Copy text'
            title='Copy text'
          >
            <SvgCopyText className='responseChatBubble__actionBlock__button__icon' />
            <div role='alert' aria-live='polite'>
              {copied && (
                <div role='alert' aria-live='polite' className='responseChatBubble__actionBlock__button__tip'>
                  text copied
                </div>
              )}
            </div>
          </button>
          <button
            className={`responseChatBubble__actionBlock__button
              ${feedback === Feedback.GOOD && 'responseChatBubble__actionBlock__button--good'}`}
            onClick={onThumbsUpOnClick}
            aria-disabled={feedback !== Feedback.MEH}
            type='button'
            aria-label='Good response'
            title='Good response'
            aria-pressed={feedback === Feedback.GOOD}
          >
            <SvgThumbsUp className='responseChatBubble__actionBlock__button__icon' />
          </button>
          <button
            ref={thumbsDownButtonRef}
            className={`responseChatBubble__actionBlock__button
              ${feedback === Feedback.BAD && 'responseChatBubble__actionBlock__button--bad'}`}
            onClick={onThumbsdownClick}
            aria-disabled={feedback !== Feedback.MEH}
            type='button'
            aria-label='Bad response'
            title='Bad response'
            data-testid='response-thumbs-down'
            aria-pressed={feedback === Feedback.BAD}
          >
            <SvgThumbsDown className='responseChatBubble__actionBlock__button__icon' />
          </button>
        </div>
      )}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => {
          setShowFeedbackModal(false)
          setTimeout(() => thumbsDownButtonRef.current?.focus(), 50)
        }}
        responseId={responseId}
        surveyId={surveyId}
        feedbackId={feedbackId}
      />
    </div>
  )
}
