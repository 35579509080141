'use client'

import { Components } from 'react-markdown'
import Markdown from '../Markdown.client'
import './user-chat-bubble.scss'

interface IUserChatBubbleProps {
  children: React.ReactNode
  className?: string
}

const overrides: Components = {
  p: function p({ children }) {
    return <h3>{children}</h3>
  }
}

export default function UserChatBubble({
  children,
  className,
}: IUserChatBubbleProps) {
  return (
    <div
      className={`userChatBubble ${className ?? ''}`}
      data-testid='chatbot-user-msg'
    >
      <div className='userChatBubble__message'>
        {typeof children === 'string' ? (
          <Markdown overrides={overrides}>{children}</Markdown>
        ) : (
          children
        )}
      </div>
      <p className='userChatBubble__avatar'><span className='sr-only'>By</span> You</p>
    </div>
  )
}
